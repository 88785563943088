import emojiRegex from "emoji-regex/es2015";

const emojiFindingRegex: RegExp = emojiRegex();

export const stringIf = (truthy: unknown, str: string, notStr = ""): string => (!!truthy ? str : notStr);

export function makeMailToUrl(emails: string | string[], contents: { subject?: string; body?: string } = {}): string {
  emails = Array.isArray(emails) ? emails : [emails];

  return `mailto:${emails.join(",")}?${Object.keys(contents)
    .map((key) => `${key}=${encodeURIComponent(contents[key])}`)
    .join("&")}`;
}

export function isEmailish(email?: string) {
  return !email || /^[^@]+?(@([^@]+?(\.?[^@]+)?)?)?$/.test(email);
}

export function isEmail(email?: string | null) {
  if (!email) return false;
  return /.+\@(.{2,}\.)+.+/.test(email);
}

export function isWorkEmail(email?: string | null) {
  if (!email) return false;
  return !/@(gmail|googlemail|outlook|icloud|mac|live|hotmail|yahoo|fastmail)\.com$/i.test(email);
}

export function ucfirst(str?: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Changes snake case to words, replacing _ with space.
 * @param str The snake cased string to change to words
 * @param letterCase How to handle case change.  If blank case will remain unchanged else:
 * - `upper`: change all letters to upper-case
 * - `lower`: change all letters to lower-case
 * - `cap`: capitalize the first letter of each word
 * @returns A string with the words from the snake-cased string
 */
export function snakeToWords(str: string, letterCase?: "upper" | "lower" | "cap") {
  let words = str.split("_");

  switch (letterCase) {
    case "cap":
      words = words.map(ucfirst);
      break;
    case "lower":
      words = words.map((s) => s.toLowerCase());
      break;
    case "upper":
      words = words.map((s) => s.toUpperCase());
      break;
  }

  return words.join(" ");
}

export function titlecase(str: string, joiner: string = "") {
  if (!str) return "";
  return str
    .split(/[^\w\d]+/)
    .map(ucfirst)
    .join(joiner);
}

export function camelcase(str: string) {
  if (!str) return "";
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, ch) => ch.toUpperCase());
}

export function kebabcase(str: string | undefined) {
  if (!str) return "";
  if (undefined) return "";
  return str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
}

export function pluralize(count: number, root: string, plural: string = "s") {
  return `${root}${Math.abs(count) !== 1 ? plural : ""}`;
}

export function labelize(count: number, root: string, plural?: string) {
  return `${count} ${pluralize(count, root, plural)}`;
}

export function parseEmoji(text: string): { emoji?: string; textWithoutEmoji: string } {
  const emoji = new RegExp(emojiFindingRegex, "u").exec(text);

  return {
    emoji: emoji && emoji.index === 0 ? emoji[0] : undefined,
    textWithoutEmoji: emoji && emoji.index === 0 ? text.replace(emoji[0], "").trimStart() : text,
  };
}

export function titleWithEmoji(emoji: string | null | undefined, text?: string): string {
  if (!emoji) return text || "";
  const foundEmoji = emojiFindingRegex.exec(text || "");

  if (foundEmoji && foundEmoji.index === 0) {
    return `${emoji}${text || ""}`.trimStart();
  }
  return `${emoji} ${text || ""}`.trimStart();
}

export function list(items: string[], conjunction = "and") {
  if (!conjunction || items.length < 2) return items.join(", ");
  if (items.length === 2) return items.join(` ${conjunction} `);
  return items
    .slice(0, -1)
    .concat(`${conjunction} ${items[items.length - 1]}`)
    .join(", ");
}

export function isJson(str: string) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return ["[object Object]", "[object Array]"].includes(type);
  } catch (err) {
    return false;
  }
}

export class Parser<T> {
  static Json = new Parser<{ [key: string]: unknown }>(
    "json",
    (str) => isJson(str),
    (str) => JSON.parse(str)
  );

  static Array = new Parser<string[]>(
    "array",
    (str) => /(?:\[?([\w\d._\-+]+)[\s,]+?)\]?/g.test(str),
    (str) => {
      const match = str.match(/(?:([\w\d._\-+]+)\s?[,]?)/g);
      if (!match?.length) return undefined;
      return Array.from(match);
    }
  );

  static Boolean = new Parser<boolean>(
    "boolean",
    (str) => /^(true|false)$/i.test(str),
    (str) => {
      const match = str.match(/^(true|false)$/i);
      if (!match || !match.length) return undefined;
      if (match[0].toLowerCase() === "true") return true;
      if (match[0].toLowerCase() === "false") return false;
      return undefined;
    }
  );

  static Number = new Parser<number>(
    "number",
    (str) => /^(\d*\.?\d+)$/i.test(str),
    (str) => {
      const match = str.match(/^(\d*\.?\d+)$/i);
      if (!match?.length) return undefined;
      const num = Number(match[0]);
      return !isNaN(num) ? num : undefined;
    }
  );

  static Null = new Parser<number>(
    "null",
    (str) => /^null$/i.test(str),
    (str) => {
      const match = str.match(/^null$/i);
      return match?.length ? null : undefined;
    }
  );

  static Undefined = new Parser<number>(
    "undefined",
    (str) => /^undefined$/i.test(str),
    (str) => {
      const match = str.match(/^undefined$/i);
      return match?.length ? null : undefined;
    }
  );

  static parse(str: string) {
    const parser = [Parser.Json, Parser.Array, Parser.Number, Parser.Boolean, Parser.Null, Parser.Undefined].find((p) =>
      p.test(str)
    );
    return parser ? parser.parse(str) : str;
  }

  name: string;
  test: (str: string) => boolean;
  parse: (str: string) => T | null | undefined;

  constructor(name: string, test: (str: string) => boolean, parse: (str: string) => T | null | undefined) {
    this.name = name;
    this.test = test;
    this.parse = parse;
  }
}

export default {
  isEmailish,
  isEmail,
  isWorkEmail,
  ucfirst,
  titlecase,
  pluralize,
  labelize,
  isJson,
  Parser,
};
